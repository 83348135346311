import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack,Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Swal from 'sweetalert2';

import axios from 'axios'
// ----------------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function AddNewVoucherForm() {

  const navigate = useNavigate()

  const [code, setCode] = useState('')
  const [duration, setDuration] = useState('')

  const token = localStorage.getItem("token")
  const config = {
    headers: { 
      Authorization: `Bearer ${token}`,
      ContentType: 'application/json',
   }
  };

  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string().required('First name required'),
    // lastName: Yup.string().required('Last name required'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    code: '',
    duration: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = () => {
    axios.post(BASE_URL + "voucher/",
    {
      code: code,
      durationInDays: duration,
    },
    config)
    .then(response => {
      Swal.fire({
        icon: 'success',
        title: 'New voucher was created!!!',
      }).then(res => {
        navigate('/dashboard/voucher')
      })
    })
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField 
                  name="code" 
                  placeholder="Code" 
                  value={code} 
                  onChange={(e)=>setCode(e.target.value)} />
          <RHFTextField name="duration" placeholder="Duration"
            value={duration}
            onChange={(e)=>{setDuration(e.target.value)}} />
        </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
