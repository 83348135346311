import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import axios from 'axios';
import { maxWidth } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const BASE_URL = process.env.REACT_APP_BASE_URL

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, selected, filterName, onFilterName,allowedAdd,route }) {


  const navigate = useNavigate()

  const token = localStorage.getItem("token")
  const config = {
    headers: { 
      Authorization: `Bearer ${token}`,
      ContentType: 'application/json',
   }
  };
  const deleteItems = () => {
    console.log(selected)
    for(const el of selected){
      axios.delete(BASE_URL + `${route}/`+el,
      config)
    }
    Swal.fire({
      icon: 'success',
      title: 'Vouchers was deleted!!!',
    })
    window.location.reload()
  }

  const handleAddItem = () =>{
    navigate(`/dashboard/${route}/add`)
    // window.open(`/dashboard/${route}/add`);
  }

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={deleteItems}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <div>
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
        {allowedAdd && <Tooltip title="Add new voucher">
          <IconButton onClick={handleAddItem}>
            <Iconify icon="mingcute:add-fill" />
          </IconButton>
        </Tooltip>}
        </div>
      )}
    </RootStyle>
  );
}
