import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Swal from 'sweetalert2';

import axios from 'axios'
// ----------------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function AccountForm({ id }) {
  const navigate = useNavigate();

  const [subStatus, setSubStatus] = useState([])
  const [statusSub, setStatusSub] = useState("")
  const [name,setName] = useState("")
  const [userName,setUserName] = useState("")
  const [city,setCity] = useState("")
  const [street,setStreet] = useState("")
  const [country,setCountry] = useState("")
  const [county,setCounty] = useState("")
  const [phoneCode,setPhoneCode] = useState("")
  const [phoneNumber,setPhoneNumber] = useState("")
  const [zipAreaCode,setZipAreaCode] = useState("")

  const [regStatus, setRegStatus] = useState([])
  const [statusReg, setStatusReg] = useState("")

  const [roles, setRoles] = useState([])
  const [role, setRole] = useState("")

  const [edit, setIsEdit] = useState(true)

  const [user,setUser] = useState({
          id:"",
          name:"",
          username:"",
          city:"",
          country:"",
          county: "",
          street:"",
          phoneCode:"",
          phoneNumber:"",
          registrationStatus:"",
          subscribeStatus:"",
          rolesNames:"",
          zipAreaCode:"",
  })

  const token = localStorage.getItem("token")
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    axios.get(BASE_URL + "users/" + id,
      config
    )
      .then(response => {
        let data = response.data
        console.log(response.data)
        let info = {
          id: data.id,
          name: data.name,
          username: data.username,
          city: data.city,
          country: data.country,
          county: data.county,
          street: data.street,
          phoneCode: data.phoneCode,
          phoneNumber: data.phoneNumber,
          registrationStatus: data.registrationStatus.name,
          subscribeStatus: data.subscribeStatus.name,
          rolesNames: data.rolesNames[0],
          zipAreaCode: data.zipAreaCode
        }
        setUser(info)
        setStatusSub(info.subscribeStatus)
        setStatusReg(info.registrationStatus)
        setRole(info.rolesNames)
        setName(info.name)
        setUserName(info.username)
        setCity(info.city)
        setCountry(info.country)
        setCounty(info.county)
        setStreet(info.street)
        setPhoneCode(info.phoneCode)
        setPhoneNumber(info.phoneNumber)
        setZipAreaCode(info.zipAreaCode)
        setRole(info.rolesNames)
        setStatusReg(info.registrationStatus)
        setStatusSub(info.subscribeStatus)
      })

    axios.get(BASE_URL + "sub-status")
      .then(
        response => {
          setSubStatus(response.data)
        }
      )

    axios.get(BASE_URL + "reg-status")
      .then(
        response => {
          setRegStatus(response.data)
        }
      )

    axios.get(BASE_URL + "user-role")
      .then(
        response => {
          setRoles(response.data)
        }
      )
  }, [])


  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string().required('First name required'),
    // lastName: Yup.string().required('Last name required'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const handleChangeSubStatus = (e) => {

    console.log(e.target.value)
    setStatusSub(e.target.value)
  }

  const handleChangeRegStatus = (e) => {

    console.log(e.target.value)
    setStatusReg(e.target.value)
  }

  const handleChangeRole = (e) => {

    console.log(e.target.value)
    setRole(e.target.value)
  }

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const isEditting = () => {
    setIsEdit(false)
  }

  const onSubmit = () => {
    axios.put(BASE_URL+"admin/user/edit/" + id,
    {     
          id:user.id,
          name: name,
          username: userName,
          city: city,
          country: country,
          county: county,
          street: street,
          phoneCode: phoneCode,
          phoneNumber: phoneNumber,
          registrationStatus: statusReg,
          subscribeStatus: statusSub,
          rolesNames: role,
          zipAreaCode: zipAreaCode,
    },
    config)
    .then(response => {
      Swal.fire({
        icon: 'success',
        title: 'The changes have been saved!',
      }).then(res => {
        window.location.reload()
      })
    })
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="name" label="Name" value={name} disabled={edit}
            onChange={(e)=>setName(e.target.value)} />
          <RHFTextField name="username" label="User name"
            value={userName} disabled={edit}
            onChange={(e)=>setUserName(e.target.value)} />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="city" label="city"
            value={city} disabled={edit}
            onChange={(e)=>setCity(e.target.value)} />
          <RHFTextField name="street" label="street"
            value={street} disabled={edit}
            onChange={(e)=>setStreet(e.target.value)}/>
        </Stack>

        <RHFTextField name="country" label="country"
          value={country} disabled={edit}
          onChange={(e)=>setCountry(e.target.value)} />

        <RHFTextField name="county" label="county"
          value={county} disabled={edit}
          onChange={(e)=>setCounty(e.target.value)} />

        <RHFTextField name="phoneCode" label="phone code"
          value={phoneCode} disabled={edit}
          onChange={(e)=>setPhoneCode(e.target.value)} />

        <RHFTextField name="phoneNumber" label="phone number"
          value={phoneNumber} disabled={edit}
          onChange={(e)=>setPhoneNumber(e.target.value)} />

        <RHFTextField name="zipAreaCode" label="zipAreaCode"
          value={zipAreaCode} disabled={edit}
          onChange={(e)=>setZipAreaCode(e.target.value)} />

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Subscription Status</InputLabel>
          <Select
            disabled={edit}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statusSub}
            label="Subscription Status"
            onChange={e => handleChangeSubStatus(e)}
          >
            {
              subStatus.map(
                (el) => {
                  return (
                    <MenuItem value={el.name}>{el.name}</MenuItem>
                  )
                }
              )
            }
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Registration Status</InputLabel>
          <Select
            disabled={edit}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statusReg}
            label="Registration Status"
            onChange={e => handleChangeRegStatus(e)}
          >
            {
              regStatus.map(
                (el) => {
                  return (
                    <MenuItem value={el.name}>{el.name}</MenuItem>
                  )
                }
              )
            }
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Role</InputLabel>
          <Select
            disabled={edit}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={role}
            label="Role"
            onChange={e => handleChangeRole(e)}
          >
            {
              roles.map(
                (el) => {
                  return (
                    <MenuItem value={el.name}>{el.name}</MenuItem>
                  )
                }
              )
            }
          </Select>
        </FormControl>

        {
          edit ?
            <Button fullWidth size="large" variant="contained" onClick={isEditting}>
              Edit
            </Button>
            :
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
        }
      </Stack>
    </FormProvider>
  );
}
