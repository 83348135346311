
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
// components
import Page from '../components/Page';
// sections
import AddNewVoucherForm from 'src/sections/auth/register/AddNewVoucherForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function AddNewVoucher() {

  return (
    <Page title="Add new voucher">
      <RootStyle>
        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
                Add new voucher
            </Typography>
            <AddNewVoucherForm/>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
