import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Group from './pages/Group';
import Account from './pages/Account';
import Voucher from './pages/Voucher';
import EditVoucher from './pages/EditVoucher';
import AddNewVoucher from './pages/AddNewVoucher';

// ----------------------------------------------------------------------

export default function Router() {
  
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <User /> },
        { path: 'user/:id', element: <Account /> },
        { path: 'groups', element: <Group /> },
        { path: 'blog', element: <Blog /> },
        { path: 'voucher', element: <Voucher /> },
        { path: 'voucher/:id', element: <EditVoucher /> },
        { path: 'voucher/add', element: <AddNewVoucher /> }
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
