import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';
import axios from 'axios';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function UserMoreMenu({
  itemId,
  route,
  apiEndpoint,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()

  const handleChange = () =>{
    window.location.replace(`/dashboard/${route}/`+ itemId);
  }

  const token = localStorage.getItem("token")
  const config = {
    headers: { 
      Authorization: `Bearer ${token}`,
      ContentType: 'application/json',
   }
  };
  const handleDelete = () =>{
    axios.delete(
      BASE_URL + apiEndpoint + itemId,
      config
    ).then(
      response=>{
        Swal.fire({
          icon: 'success',
          title: 'Voucher was deleted!!!',
    }).then(res => {
      window.location.reload()
    })
      }
    )
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} onClick={handleChange}/>
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} onClick={handleDelete}/>
        </MenuItem>
      </Menu>
    </>
  );
}
