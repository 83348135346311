import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Swal from 'sweetalert2';

import axios from 'axios'
// ----------------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function EditVoucherForm({ id }) {
  const navigate = useNavigate();

  
  const [edit, setIsEdit] = useState(true)
  const [code, setCode] = useState('')
  const [duration, setDuration] = useState('')

  const token = localStorage.getItem("token")
  const config = {
    headers: { 
      Authorization: `Bearer ${token}`,
      ContentType: 'application/json',
   }
  };

  useEffect(() => {
    axios.get(BASE_URL + "voucher/" + id,
      config
    )
      .then(response => {
        let data = response.data
        let voucher = {
          id: data.id,
          code: data.code,
          duration: data.durationInDays,
        }
        setCode(voucher.code)
        setDuration(voucher.duration)
      })
  }, [])


  const RegisterSchema = Yup.object().shape({
    // firstName: Yup.string().required('First name required'),
    // lastName: Yup.string().required('Last name required'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    code: '',
    duration: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const isEditting = () => {
    setIsEdit(false)
  }

  const onSubmit = () => {
    axios.put(BASE_URL + "voucher/" + id,
    {
      code: code,
      durationInDays: duration,
    },
    config)
    .then(response => {
      Swal.fire({
        icon: 'success',
        title: 'The changes have been saved!',
      }).then(res => {
        window.location.reload()
      })
    })
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField 
                  name="code" 
                  label="Code" 
                  value={code} 
                  disabled={edit}
                  onChange={(e)=>setCode(e.target.value)} />
          <RHFTextField 
            name="duration" 
            label="Duration"
            value={duration} disabled={edit}
            onChange={(e)=>{setDuration(e.target.value)}} />
        </Stack>
        {
          edit ?
            <Button fullWidth size="large" variant="contained" onClick={isEditting}>
              Edit
            </Button>
            :
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
        }
      </Stack>
    </FormProvider>
  );
}
